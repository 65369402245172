export const SEARCH_COPY = 'Search\nfor a Sitter';
export const BOOKING_COPY = 'Chat then\nbook';
export const PEACE_COPY = 'Enjoy peace\nof mind';

export const HMPW_TITLE = 'How does\nMad Paws work?';
export const HMPW_ALT_TEXT = 'Couple with dogs on a forest lawn';
export const HMPW_YOUTUBE_LINK = 'https://www.youtube.com/watch?v=2fK06ev1hxM';

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 */
export const HOW_DOES_MADPAWS_WORKS_HTML_ID = 'How_does_madpaws_works';

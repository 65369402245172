import {
  Button,
  Heading,
  IconPlay,
  useMediumFromMediaQuery,
  useLargeFromMediaQuery,
} from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { trackEvent } from '~/components/analytics/analytics';
import { CLICKED_HOW_IT_WORKS } from '~/components/analytics/constants';

import styles from './HowDoesMadPawsWork.module.css';
import { SuccessFlow } from '../SuccessFlow';
import {
  HMPW_ALT_TEXT,
  HMPW_TITLE,
  HMPW_YOUTUBE_LINK,
  HOW_DOES_MADPAWS_WORKS_HTML_ID,
} from '../constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

export const HowDoesMadPawsWork = (): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const handleClick = (): void => {
    trackEvent(CLICKED_HOW_IT_WORKS);
    window?.open(HMPW_YOUTUBE_LINK, '_blank');
  };

  return (
    <section className={styles.root} id={HOW_DOES_MADPAWS_WORKS_HTML_ID}>
      <div className={styles.image}>
        <ImageWithFallback
          alt={HMPW_ALT_TEXT}
          fallback={`${publicRuntimeConfig.staticPath}/images/homepage/CoupleWithDogsLarge.jpg`}
          webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/CoupleWithDogsLarge.webp`}
        />
      </div>
      <div className={styles.content}>
        <Heading
          alignment="centerAlign"
          color="stoneColor"
          fontFamily="heading"
          size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
        >
          {HMPW_TITLE}
        </Heading>
        <SuccessFlow />
        <Button
          iconTrailing={<IconPlay />}
          label="Check out how it works"
          onClick={handleClick}
          size="large1x"
          variant="secondary"
        />
      </div>
    </section>
  );
};

import { IconCalendarBooking, IconFaceHappy, IconSearchRounded } from '@madpaws/design-system';

import styles from './SuccessFlow.module.css';
import { BOOKING_COPY, PEACE_COPY, SEARCH_COPY } from './constants';

import type { ReactElement } from 'react';

export const SuccessFlow = (): ReactElement => (
  <div className={styles.root}>
    <div className={styles.icon}>
      <IconSearchRounded size="large" />
      <div className={styles.copy}>{SEARCH_COPY}</div>
    </div>
    <div className={styles.divider} />
    <div className={styles.icon}>
      <IconCalendarBooking size="large" />
      <div className={styles.copy}>{BOOKING_COPY}</div>
    </div>
    <div className={styles.divider} />
    <div className={styles.icon}>
      <IconFaceHappy size="large" />
      <div className={styles.copy}>{PEACE_COPY}</div>
    </div>
  </div>
);
